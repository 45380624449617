






import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {defineComponent, ref} from '@vue/composition-api';
import LabTask from '@/tasks/components/dynamic-questions/labs/LabTask.vue';
import LabSim from '@/tasks/components/simulations/LabSim.vue';
import {useAuthService} from '@/auth/services/authService';
import {useInertia} from '@/inertia/plugins/inertia';

export default defineComponent({
  name: 'NewGrahamsLawSIM',
  components: {LabTask, LabSim},
  mixins: [DynamicQuestionMixin()],

  setup() {
    // set this way because if the user has not already been got, it will return null
    let randomGasesSeed = ref<number | null>(useInertia().getSeed());

    return {randomGasesSeed};
  },
});
